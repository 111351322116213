import React, {Component} from "react";

import {Container, Row, Col} from "reactstrap";

class NotFoundText extends Component {
	render() {
		return (
			<section className="section section-lg">
				<Container>
					<Row className="row-grid justify-content-center">
						<Col className="text-center" lg="8">
							<h2 className="display-3">
								Page not found
							</h2>
							<p className="lead">
								The requested URL was not found on this server.

								If you entered the URL manually please check your
								spelling and try again.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
		);
	}
}

export default NotFoundText;
