import React, {Component} from "react";

import {FormGroup, Input, InputGroupAddon, InputGroupText, InputGroup, Container, Button, Col, Row} from "reactstrap";
import classnames from "classnames";
import axios from "axios";

class ContactForm extends Component {
	constructor(props) {
		super(props);

		this.state = {contactName: "", contactEmail: "", contactMessage: ""};
		this.state = {nameInvalid: false, emailInvalid: false, messageInvalid: false};

		// Form changes
		this.handleNameChange = this.handleNameChange.bind(this);
		this.handleEmailChange = this.handleEmailChange.bind(this);
		this.handleMessageChange = this.handleMessageChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	// Form changes
	handleNameChange(event) {
		let name = event.target.value;

		if(name.length === 0) {
			this.setState({nameInvalid: true});
		}

		if(!this.state.nameInvalid) {
			this.setState({contactName: name});
		}
	}

	handleEmailChange(event) {
		const emailRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
		let email = event.target.value;

		if(email.length === 0 || !emailRegexp.test(email)) {
			this.setState({emailInvalid: true});
		} else {
			this.setState({emailInvalid: false});
			this.setState({contactEmail: email});
		}
	}

	handleMessageChange(event) {
		let message = event.target.value;

		if(message.length === 0 || message.length < 20) {
			this.setState({messageInvalid: true});
		} else {
			this.setState({messageInvalid: false});
			this.setState({contactMessage: message});
		}
	}

	handleSubmit(event) {
		if(!this.state.nameInvalid && !this.state.emailInvalid && !this.state.messageInvalid) {
			const json = JSON.stringify(
				{
					username: "Contact - Contact form",
					avatar_url: "",
					embeds: [
						{
							title: "New Contact",
							color: 65535,
							fields: [
								{
									name: "Full name",
									value: this.state.contactName
								},

								{
									name: "Email",
									value: this.state.contactEmail
								},

								{
									name: "Message",
									value: this.state.contactMessage
								}
							]
						}
					]
				}
			);

			axios.post('https://discord.com/api/webhooks/843509630365204560/njVCvs-3zJqoPfP0_6DtexexCmM4m-KtE88sI9LhRVSqnK3qopVFo6DghA55KfN-tx0L', json, {
				headers: {
					'Content-Type': 'application/json'
				}
			});
		}
	}

	render() {
		return (
			<section className="section section-lg">
				<Container>
					<Row className="row-grid justify-content-center">
						<Col className="text-center" lg="12">
							<h2 className="display-3">
								More questions?
							</h2>

							<h5 className="display-5">
								Send us an inquiry below.
							</h5>
						</Col>
					</Row>

					<FormGroup
						className={
							classnames("mt-5", {
								focused: this.state.nameFocused,
								"has-danger": this.state.nameInvalid
							})
						}
					>
						<InputGroup className="input-group-alternative">
							<InputGroupAddon addonType="prepend">
								<InputGroupText>
									<i className="ni ni-user-run" />
								</InputGroupText>
							</InputGroupAddon>
							<Input
								placeholder="Your name"
								type="text"
								onFocus={e => this.setState({nameFocused: true})}
								onBlur={e => this.setState({nameFocused: false})}
								onChange={e => this.handleNameChange(e)}
							/>
						</InputGroup>
					</FormGroup>

					<FormGroup
						className={
							classnames({
								focused: this.state.emailFocused,
								"has-danger": this.state.emailInvalid
							})
						}
					>
						<InputGroup className="input-group-alternative">
							<InputGroupAddon addonType="prepend">
								<InputGroupText>
									<i className="ni ni-email-83" />
								</InputGroupText>
							</InputGroupAddon>
							<Input
								className={"is-invalid"}
								placeholder="Email address"
								type="email"
								onFocus={e => this.setState({emailFocused: true})}
								onBlur={e => this.setState({emailFocused: false})}
								onChange={e => this.handleEmailChange(e)}
							/>
						</InputGroup>
					</FormGroup>

					<FormGroup
						className={
							classnames("mb-4", {
								"has-danger": this.state.messageInvalid
							})
						}
					>
						<Input
							className="form-control-alternative"
							cols="80"
							name="name"
							placeholder="Type a message..."
							rows="4"
							type="textarea"
							onChange={e => this.handleMessageChange(e)}
						/>
					</FormGroup>
					<div>
						<Button
							block
							className="btn-round"
							color="default"
							size="lg"
							type="button"
							onClick={e => this.handleSubmit()}
						>
							Send Message
						</Button>
					</div>
				</Container>
			</section>
		);
	}
}

export default ContactForm;
