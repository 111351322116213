import React, {Component} from "react";

import MainNavbar from "components/MainNavbar";
import MainFooter from "components/MainFooter";
import MainHero from "../components/MainHero";

import ContactForm from "../sections/Contact/ContactForm";

import 'assets/css/style.css';

class About extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<>
				<MainNavbar pageName="contact" />

				<main ref="contact">
					<MainHero locationTitle="Contact Us" locationName="Contact" />
					<ContactForm />
				</main>

				<MainFooter />
			</>
		);
	}
}

export default About;
