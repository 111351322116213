import React, {Component} from "react";

import {Card, CardBody, Col, Container, Row} from "reactstrap";

class Services extends Component {
	render() {
		return (
			<section className="section pb-0 bg-gradient-warning">
				<Container>
					<Row className="row-grid align-items-center">
						<Col className="order-lg-2 ml-lg-auto" md="6">
							<div className="position-relative pl-md-5">
								<img
									alt="..."
									className="img-center img-fluid floating"
									src={require("assets/img/ill/ill-2.svg")}
								/>
							</div>
						</Col>

						<Col className="order-lg-1" lg="6">
							<div className="d-flex px-3">
								<div className="pl-4">
									<h4 className="display-3 text-white">Neleks Services</h4>
									<p className="text-white">
										Products that can make your life easier.
									</p>
								</div>
							</div>

							<Card className="shadow shadow-lg--hover mt-5">
								<CardBody>
									<div className="d-flex px-3">
										<div>
											<div className="icon icon-shape bg-gradient-info rounded-circle text-white">
												<i className="fa fa-pie-chart" />
											</div>
										</div>
										<div className="pl-4">
											<h5 className="title text-info">
												Analysts Tools
											</h5>
											<p>
												With help of our tools you can easily track data of your
												products and services.
											</p>
										</div>
									</div>
								</CardBody>
							</Card>

							<Card className="shadow shadow-lg--hover mt-5">
								<CardBody>
									<div className="d-flex px-3">
										<div>
											<div className="icon icon-shape bg-gradient-success rounded-circle text-white">
												<i className="fa fa-terminal" />
											</div>
										</div>
										<div className="pl-4">
											<h5 className="title text-success">
												Development Tools
											</h5>
											<p>
												Need help with your project? We've got your back!
											</p>
										</div>
									</div>
								</CardBody>
							</Card>

							<Card className="shadow shadow-lg--hover mt-5">
								<CardBody>
									<div className="d-flex px-3">
										<div>
											<div className="icon icon-shape bg-gradient-warning rounded-circle text-white">
												<i className="fa fa-graduation-cap" />
											</div>
										</div>
										<div className="pl-4">
											<h5 className="title text-warning">
												Solutions for Students
											</h5>
											<p>
												Free software to aid students in their education journey!
											</p>
										</div>
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>

				<div className="separator separator-bottom separator-skew zindex-100">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						preserveAspectRatio="none"
						version="1.1"
						viewBox="0 0 2560 100"
						x="0"
						y="0"
					>
						<polygon
							className="fill-white"
							points="2560 0 2560 100 0 100"
						/>
					</svg>
				</div>
			</section>
		);
	}
}

export default Services;
