import React, {Component} from "react";

import {Container, Row, Col} from "reactstrap";

class ProductList extends Component {
	render() {
		return (
			<section className="section section-lg">
				<Container>
					{/*<Row className="row-grid justify-content-center">*/}
					{/*	<Col className="text-center" lg="8">*/}
					{/*		<h2 className="display-3">*/}
					{/*			For all*/}
					{/*		</h2>*/}

					{/*		<a href="https://play.google.com/store/apps/details?id=com.neleks.readlux" target="_blank">*/}
					{/*			<img*/}
					{/*				alt="readlux_logo"*/}
					{/*				className="img-fluid rounded shadow-lg--hover"*/}
					{/*				src={require("assets/img/products/readlux.png")}*/}
					{/*			/>*/}
					{/*		</a>*/}
					{/*	</Col>*/}
					{/*</Row>			*/}

					<Row className="row-grid justify-content-center">
						<Col className="text-center" lg="8">
							<h2 className="display-3">
								Powered by Neleks Cloud
							</h2>

							<p>Projects we helped build and projects powered by our infrastructure</p>

							<a href="https://ospruleeiskrice.splet.arnes.si" target="_blank">
								<img
									alt="readlux_logo"
									className="img-fluid rounded shadow-lg--hover"
									src={require("assets/img/products/eiskrice.png")}
								/>
							</a>
						</Col>
					</Row>
				</Container>
			</section>
		);
	}
}

export default ProductList;
