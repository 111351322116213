import React, {Component} from "react";

import {Link} from "react-router-dom";

import {Container, Row, Col} from "reactstrap";

class MainHero extends Component {
	render() {
		return (
			<div className="position-relative">
				<section className="section section-lg section-shaped">
					<div className="shape shape-style-3 ShapeNeleks">
					</div>

					<Container className="container d-flex align-items-center py-md--3 text-center">
						<div className="col px-0">
							<Row className="d-flex align-items-center" style={{marginTop: 100}}>
								<Col lg="12">
									<h1 className="display-5 text-white">
										{this.props.locationTitle}
									</h1>

									<p className="lead text-white">
										<Link to={{pathname: "/"}}>
											Home
										</Link>

										&nbsp;/&nbsp;

										{this.props.locationName}
									</p>
								</Col>
							</Row>
						</div>
					</Container>
				</section>
			</div>
		);
	}
}

export default MainHero;
