import React from "react";

import 'assets/css/style.css';

import MainNavbar from "components/MainNavbar";
import MainFooter from "components/MainFooter";
import MainHero from "../components/MainHero";

import NotFoundText from "../sections/NotFound/NotFoundText";

function NotFound({location}) {
	return (
		<>
			<MainNavbar pageName="none" />

			<main>
				<MainHero locationTitle="Error 404" locationName={location.pathname.replace('/', '')} />
				<NotFoundText />
			</main>

			<MainFooter />
		</>
	);
}

export default NotFound;