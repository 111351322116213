import React from "react";

import {Link} from "react-router-dom";

import {
	DropdownMenu,
	DropdownItem,
	DropdownToggle,
	UncontrolledDropdown,
	NavbarBrand,
	Navbar,
	NavLink,
	Nav,
	Container,
	Row,
	Col,
} from "reactstrap";

import profilePicture from '../assets/img/brand/default-profile.jpg';

class MainNavbar extends React.Component {
	render() {
		return (
			<header className="header-global">
				<Navbar
					className="navbar-expand-lg bg-white navbar-absolute shadow-sm"
					expand="lg"
					id="navbar-main"
				>
					<Container>
						<NavbarBrand className="mr-lg-5" to="/" tag={Link}>
							<NavbarBrand>Neleks Software</NavbarBrand>
						</NavbarBrand>
						<button className="navbar-toggler" id="navbar_global">
							<span className="navbar-toggler-icon" />
						</button>
						<div className="navbar-collapse-header">
							<Row>
								<Col className="collapse-brand" xs="6">
									<Link to="/">
										<img
											alt="..."
											src={require("assets/img/brand/argon-react.png")}
										/>
									</Link>
								</Col>
								<Col className="collapse-close" xs="6">
									<button className="navbar-toggler" id="navbar_global">
										<span />
										<span />
									</button>
								</Col>
							</Row>
						</div>

						<Nav className="navbar-nav-hover align-items-lg-center" navbar>
							<NavLink active={this.props.pageName === "home"} to="/" tag={Link}>Home</NavLink>
							<NavLink active={this.props.pageName === "about"} to="/about" tag={Link}>About</NavLink>
							<NavLink active={this.props.pageName === "products"} to="/products" tag={Link}>Products</NavLink>
							<NavLink active={this.props.pageName === "contact"} to="/contact" tag={Link}>Contact us</NavLink>
						</Nav>

						{/*<Nav className="navbar-nav-hover align-items-lg-center ml-lg-auto" navbar>
							<UncontrolledDropdown nav>
								<DropdownToggle nav style={{padding: 0}}>
									<img alt="profilePicture" src={profilePicture}
										 className="ProfilePicture shadow-lg--hover" />
								</DropdownToggle>

								<DropdownMenu>
									<DropdownItem to="/register-page" tag={Link}>
										Login / Register
									</DropdownItem>
								</DropdownMenu>
							</UncontrolledDropdown>
						</Nav>*/}
					</Container>
				</Navbar>
			</header>
		);
	}
}

export default MainNavbar;
