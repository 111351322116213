import React, {Component} from "react";

import {Button, Col, Container, Row} from "reactstrap";

class Privacy extends Component {
	render() {
		return (
			<section className="section section-lg">
				<Container>
					<Row className="row-grid justify-content-center">
						<Col className="text-center" lg="8">
							<h2 className="display-3">
								Neleks is <b>privacy</b>
							</h2>
							<p className="lead">
								We are fully committed to helping you keep your data to yourself. We are doing
								our best at integrating services in our products that are transparent and
								focused on privacy.
							</p>

{/*							<div className="btn-wrapper">
								<Button className="mb-3 mb-sm-0" color="primary" href="https://privacy.neleks.com">
									Read More
								</Button>
							</div>*/}
						</Col>
					</Row>
				</Container>
			</section>
		);
	}
}

export default Privacy;
