import React, {Component} from "react";

import axios from 'axios';
import Keycloak from 'keycloak-js';
import classnames from "classnames";

import MainNavbar from "components/MainNavbar";
import MainFooter from "components/MainFooter";

import Hero from "../sections/Index/Hero";
import Spacing from "../sections/Index/Spacing";
import Services from "../sections/Index/Services";
import Privacy from "../sections/Index/Privacy";
import Develop from "../sections/Index/Develop";

import 'assets/css/style.css';
import {Button, Card, CardBody, Col, Container, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Row} from "reactstrap";

class Index extends Component {
	constructor(props) {
		super(props);

		this.state = {contactName: "", contactEmail: "", contactMessage: ""};
		this.state = {nameInvalid: false, emailInvalid: false, messageInvalid: false};

		this.state = {keycloak: null, authenticated: false, email: ""};

		// Form changes
		this.handleNameChange = this.handleNameChange.bind(this);
		this.handleEmailChange = this.handleEmailChange.bind(this);
		this.handleMessageChange = this.handleMessageChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	state = {}

	componentDidMount() {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
		this.refs.main.scrollTop = 0;

		const keycloak = Keycloak('/keycloak.json');
	}

	// Form changes
	handleNameChange(event) {
		let name = event.target.value;

		if(name.length === 0) {
			this.setState({nameInvalid: true});
		}

		if(!this.state.nameInvalid) {
			this.setState({contactName: name});
		}
	}

	handleEmailChange(event) {
		const emailRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
		let email = event.target.value;

		if(email.length === 0 || !emailRegexp.test(email)) {
			this.setState({emailInvalid: true});
		} else {
			this.setState({emailInvalid: false});
			this.setState({contactEmail: email});
		}
	}

	handleMessageChange(event) {
		let message = event.target.value;

		if(message.length === 0 || message.length < 20) {
			this.setState({messageInvalid: true});
		} else {
			this.setState({messageInvalid: false});
			this.setState({contactMessage: message});
		}
	}

	handleSubmit(event) {
		if(!this.state.nameInvalid && !this.state.emailInvalid && !this.state.messageInvalid) {
			const json = JSON.stringify(
				{
					username: "Contact - Homepage",
					avatar_url: "",
					embeds: [
						{
							title: "New Contact",
							color: 65535,
							fields: [
								{
									name: "Full name",
									value: this.state.contactName
								},

								{
									name: "Email",
									value: this.state.contactEmail
								},

								{
									name: "Message",
									value: this.state.contactMessage
								}
							]
						}
					]
				}
			);

			axios.post('https://discord.com/api/webhooks/843509630365204560/njVCvs-3zJqoPfP0_6DtexexCmM4m-KtE88sI9LhRVSqnK3qopVFo6DghA55KfN-tx0L', json, {
				headers: {
					'Content-Type': 'application/json'
				}
			});
		}
	}

	render() {
		return (
			<>
				<MainNavbar pageName="home" />
				<main ref="main">
					<Hero />
					<Spacing />
					<Services />
					<Privacy />
					<Develop />

					<section className="section section-lg pt-lg-0 section-contact-us">
						<Container>
							<Row className="justify-content-center mt--300 text-center">
								<Col lg="8">
									<Card className="bg-gradient-secondary shadow">
										<CardBody className="p-lg-5">
											<h4 className="mb-1">More questions?</h4>
											<p className="mt-0">
												Send us an inquiry below.
											</p>

											<FormGroup
												className={classnames("mt-5", {
													focused: this.state.nameFocused,
													"has-danger": this.state.nameInvalid
												})}
											>
												<InputGroup className="input-group-alternative">
													<InputGroupAddon addonType="prepend">
														<InputGroupText>
															<i className="ni ni-user-run" />
														</InputGroupText>
													</InputGroupAddon>
													<Input
														placeholder="Your name"
														type="text"
														onFocus={e => this.setState({ nameFocused: true })}
														onBlur={e => this.setState({ nameFocused: false })}
														onChange={e => this.handleNameChange(e)}
													/>
												</InputGroup>
											</FormGroup>

											<FormGroup
												className={
													classnames({
														focused: this.state.emailFocused,
														"has-danger": this.state.emailInvalid
													})
												}
											>
												<InputGroup className="input-group-alternative">
													<InputGroupAddon addonType="prepend">
														<InputGroupText>
															<i className="ni ni-email-83" />
														</InputGroupText>
													</InputGroupAddon>
													<Input
														className={"is-invalid"}
														placeholder="Email address"
														type="email"
														onFocus={e => this.setState({ emailFocused: true })}
														onBlur={e => this.setState({ emailFocused: false })}
														onChange={e => this.handleEmailChange(e)}
													/>
												</InputGroup>
											</FormGroup>

											<FormGroup
												className={
													classnames("mb-4", {
														"has-danger": this.state.messageInvalid
													})
												}
											>
												<Input
													className="form-control-alternative"
													cols="80"
													name="name"
													placeholder="Type a message..."
													rows="4"
													type="textarea"
													onChange={e => this.handleMessageChange(e)}
												/>
											</FormGroup>
											<div>
												<Button
													block
													className="btn-round"
													color="default"
													size="lg"
													type="button"
													onClick={e => this.handleSubmit()}
												>
													Send Message
												</Button>
											</div>
										</CardBody>
									</Card>
								</Col>
							</Row>
						</Container>
					</section>
				</main>

				<MainFooter />
			</>
		);
	}
}

export default Index;
