import React, {Component} from "react";

import MainNavbar from "components/MainNavbar";
import MainFooter from "components/MainFooter";
import MainHero from "../components/MainHero";

import AboutText from "../sections/About/AboutText";

import 'assets/css/style.css';

class About extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<>
				<MainNavbar pageName="about" />

				<main ref="about">
					<MainHero locationTitle="About Us" locationName="About" />
					<AboutText />
				</main>

				<MainFooter />
			</>
		);
	}
}

export default About;
