import React, {Component} from "react";

import {Button, Container, Row, Col} from "reactstrap";
import Typewriter from 'typewriter-effect';

class Hero extends Component {
	render() {
		return (
			<div className="position-relative">
				<section className="section section-lg section-shaped">
					<div className="shape shape-style-3 ShapeNeleks">
					</div>

					<Container className="shape-container d-flex align-items-center py-lg">
						<div className="col px-0">
							<Row className="align-items-center">
								<Col lg="12">
									<h1 className="display-5 text-white">
										Perfect for
										<span>
											<Typewriter
												options={{
													strings: ["Startups", "Students", "Developers", "Analysts"],
													autoStart: true,
													loop: true,
												}}
											/>
										</span>
									</h1>

									<p className="lead text-white">
										Take a look at our products and find a perfect solution for you.
									</p>

									<div className="btn-wrapper">
										<Button
											className="btn-icon mb-3 mb-sm-0"
											color="info"
											href="/products"
										>
											<span className="btn-inner--icon mr-1">
												<i className="fa fa-shopping-bag" />
											</span>
											<span className="btn-inner--text">Find a solution for you</span>
										</Button>

										<Button
											className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
											color="default"
											href="/contact"
										>
											<span className="btn-inner--icon mr-1">
												<i className="fa fa-envelope" />
											</span>
											<span className="btn-inner--text">Get a quote</span>
										</Button>
									</div>
								</Col>
							</Row>
						</div>
					</Container>

					<div className="separator separator-bottom separator-skew">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							preserveAspectRatio="none"
							version="1.1"
							viewBox="0 0 2560 100"
							x="0"
							y="0"
						>
							<polygon
								className="fill-white"
								points="2560 0 2560 100 0 100"
							/>
						</svg>
					</div>
				</section>
			</div>
		);
	}
}

export default Hero;
