import React, {Component} from "react";

import {Container, Row, Col} from "reactstrap";

class AboutText extends Component {
	render() {
		return (
			<section className="section section-lg">
				<Container>
					<Row className="row-grid justify-content-center">
						<Col className="text-center" lg="8">
							<h2 className="display-3">
								Who are we?
							</h2>
							<p className="lead">
								We are a customer satisfaction-oriented software group.
								That means we provide users a suite of reliable and simple
								products. And in case things go bad we provide customers
								with reliable support on the backend.
							</p>
						</Col>
					</Row>

					<Row className="row-grid justify-content-center">
						<Col className="text-center" lg="8">
							<h2 className="display-3">
								What is our goal?
							</h2>
							<p className="lead">
								We strive to provide users with simple and privacy focused products.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
		);
	}
}

export default AboutText;
