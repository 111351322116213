import React, {Component} from "react";

import MainNavbar from "components/MainNavbar";
import MainFooter from "components/MainFooter";
import MainHero from "../components/MainHero";

import ProductList from "../sections/Products/ProductList";

import 'assets/css/style.css';

class Products extends Component {
	render() {
		return (
			<>
				<MainNavbar pageName="products" />

				<main ref="products">
					<MainHero locationTitle="Products" locationName="Products" />
					<ProductList />
				</main>

				<MainFooter />
			</>
		);
	}
}

export default Products;
