import React, {Component} from "react";

import {Col, Container, Row} from "reactstrap";

class Develop extends Component {
	render() {
		return (
			<section className="section section-lg bg-gradient-default">
				<Container className="pt-lg pb-300">
					<Row className="text-center justify-content-center">
						<Col lg="10">
							<h2 className="display-3 text-white">Build anything</h2>
							<p className="lead text-white">
								Bring your amazing idea into life with Neleks Developer tools.
							</p>
						</Col>
					</Row>
					<Row className="row-grid mt-5">
						<Col lg="4">
							<div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
								<i className="ni ni-ruler-pencil text-primary" />
							</div>
							<h5 className="text-white mt-3">Learn</h5>
							<p className="text-white mt-3">
								Get started with our tools and APIs by taking a look at our documentation.
							</p>
						</Col>

						<Col lg="4">
							<div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
								<i className="ni ni-settings text-primary" />
							</div>
							<h5 className="text-white mt-3">Develop</h5>
							<p className="text-white mt-3">
								We provide tools that can be used for development and aid you to your goal.
							</p>
						</Col>

						<Col lg="4">
							<div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
								<i className="ni ni-atom text-primary" />
							</div>
							<h5 className="text-white mt-3">Launch</h5>
							<p className="text-white mt-3">
								Upon your launch, our tools can help you analyze and measure the success of your
								projects.
							</p>
						</Col>
					</Row>
				</Container>

				<div className="separator separator-bottom separator-skew zindex-100">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						preserveAspectRatio="none"
						version="1.1"
						viewBox="0 0 2560 100"
						x="0"
						y="0"
					>
						<polygon
							className="fill-white"
							points="2560 0 2560 100 0 100"
						/>
					</svg>
				</div>
			</section>
		);
	}
}

export default Develop;
