import React, {Component} from "react";

import {Button, Col, Container, Nav, NavItem, NavLink, Row, UncontrolledTooltip} from "reactstrap";

class MainFooter extends Component {
	render() {
		return (
			<footer className="footer">
				<Container>
					<Row className="row-grid mb-5">
						<Col lg="4">
							<h3 className="heading text-primary font-weight-light mb-2">
								Neleks Software
							</h3>

							<ul className="list-unstyled">
								<li>
									<small>A customer satisfaction oriented software group.</small>
								</li>
							</ul>

							<Button
								className="btn-icon-only rounded-circle"
								color="twitter"
								href="https://twitter.com/neleks_software"
								id="tooltipTwitter"
								target="_blank"
							>
								<span className="btn-inner--icon">
									<i className="fa fa-twitter" />
								</span>
							</Button>
							<UncontrolledTooltip delay={0} target="tooltipTwitter">Twitter</UncontrolledTooltip>

							<Button
								className="btn-icon-only rounded-circle ml-1"
								color="instagram"
								href="https://instagram.com/neleks_software"
								id="tooltipInstagram"
								target="_blank"
							>
								<span className="btn-inner--icon">
									<i className="fa fa-instagram" />
								</span>
							</Button>
							<UncontrolledTooltip delay={0} target="tooltipInstagram">Instagram</UncontrolledTooltip>

							<Button
								className="btn-icon-only rounded-circle ml-1"
								color="youtube"
								href="https://youtube.com/channel/UCeoQfJsi5ooytUgSIVKKQVg"
								id="tooltipYoutube"
								target="_blank"
							>
								<span className="btn-inner--icon">
									<i className="fa fa-youtube-play" />
								</span>
							</Button>
							<UncontrolledTooltip delay={0} target="tooltipYoutube">YouTube</UncontrolledTooltip>

							<Button
								className="btn-icon-only rounded-circle ml-1"
								color="github"
								href="https://github.com/neleks"
								id="tooltipGithub"
								target="_blank"
							>
								<span className="btn-inner--icon">
									<i className="fa fa-github" />
								</span>
							</Button>
							<UncontrolledTooltip delay={0} target="tooltipGithub">Github</UncontrolledTooltip>
						</Col>

						<Col lg="4">
							<h3 className="heading text-primary font-weight-light mb-2">
								Brand Domains
							</h3>

							<ul className="list-unstyled">
								<li>
									<a href="https://help.neleks.com" target="_blank">
										Neleks Helpdesk
									</a>
								</li>

								<li>
									<a href="https://bugs.neleks.com" target="_blank">
										Neleks Bug Tracker
									</a>
								</li>

								<li>
									<a href="https://status.neleks.com" target="_blank">
										Neleks Status
									</a>
								</li>
							</ul>
						</Col>

						<Col lg="4">
							<h3 className="heading text-primary font-weight-light mb-2">
								Contact Info
							</h3>

							<ul className="list-unstyled">
								<li>
									<a href="mailto:info@neleks.com">
										info@neleks.com
									</a>
								</li>
							</ul>
						</Col>
					</Row>

					<hr />

					<Row className=" align-items-center justify-content-md-between">
						<Col md="6">
							<div className=" copyright">
								Copyright &copy; 2020 - {new Date().getFullYear()} Neleks Software. All rights reserved.
							</div>
						</Col>

{/*						<Col md="6">
							<Nav className=" nav-footer justify-content-end">
								<NavItem>
									<NavLink href="https://privacy.neleks.com">
										Privacy Policy
									</NavLink>
								</NavItem>
							</Nav>
						</Col>*/}
					</Row>
				</Container>
			</footer>
		);
	}
}

export default MainFooter;
