import React, {Component} from "react";

class Spacing extends Component {
	render() {
		return (
			<section className="section section-lg">
			</section>
		);
	}
}

export default Spacing;
