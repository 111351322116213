import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss?v1.1.0";

import Index from "views/Index";
import About from "views/About";
import Products from "views/Products";
import Contact from "views/Contact";
import NotFound from "views/NotFound";

ReactDOM.render(
	<Router>
		<Switch>
			<Route
				path="/"
				exact
				render={props => <Index {...props} />}
			/>

			<Route
				path="/about"
				exact
				render={props => <About {...props} />}
			/>

			<Route
				path="/products"
				exact
				render={props => <Products {...props} />}
			/>

			<Route
				path="/contact"
				exact
				render={props => <Contact {...props} />}
			/>

			<Redirect
				path="/contact-us"
				to="/contact"
			/>

			<Route component={NotFound} />
		</Switch>
	</Router>,

	document.getElementById("root")
);
